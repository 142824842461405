import { styled } from '@f8n-frontend/stitches';
import NextLink from 'next/link';

import FallbackTag from 'components/artworks/FallbackTag';
import Button from 'components/base/Button';
import Flex from 'components/base/Flex';
import Heading from 'components/base/Heading';
import CollectionTag from 'components/collections/CollectionTag';
import { isAdmin } from 'contexts/auth/helpers';
import useAuth from 'contexts/auth/useAuth';

import { formatInteger } from 'utils/formatters';

import { UserLight } from 'types/Account';
import { AlgoliaCollection } from 'types/Algolia';
import { CollectionCardFragment } from 'types/Collection';
import { LinkHref } from 'types/router';

type BaseArtworkCardMediaOverlayProps = {
  collection: CollectionCardFragment | AlgoliaCollection;
  collectionCreator: UserLight;
  isFallback: boolean;
};

type ArtworkCardMediaOverlayProps = BaseArtworkCardMediaOverlayProps &
  (
    | { variant: 'nft'; name: string }
    | { variant: 'stack'; href: LinkHref; itemCount: number }
  );

export default function ArtworkCardMediaOverlay(
  props: ArtworkCardMediaOverlayProps
) {
  const { collection, collectionCreator, isFallback } = props;
  const auth = useAuth();
  const isCurrentUserAdmin = isAdmin(auth);

  if (!collection || !collectionCreator) {
    return null;
  }
  const showFallbackTag = isFallback && isCurrentUserAdmin;

  return (
    <ArtworkCardMediaOverlayContainer>
      {collectionCreator && (
        <ArtworkCardCollectionWrapper>
          <CollectionTag
            collection={collection}
            creator={collectionCreator}
            size={3}
            type="avatar-text"
          />
        </ArtworkCardCollectionWrapper>
      )}
      {props.variant === 'nft' ? (
        <Heading
          size={4}
          css={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            marginTop: showFallbackTag ? 'auto' : 'unset',
          }}
        >
          {props.name}
        </Heading>
      ) : (
        <>
          <NextLink href={props.href} passHref>
            <Button as="a" css={{ alignSelf: 'center' }} variant="raised">
              View {formatInteger(props.itemCount)} NFTs
            </Button>
          </NextLink>
          <div />
        </>
      )}
      {showFallbackTag && <FallbackTag />}
    </ArtworkCardMediaOverlayContainer>
  );
}

const ArtworkCardCollectionWrapper = styled(Flex, {
  position: 'relative',
  zIndex: 1,
  width: '100%',
});

export const ArtworkCardMediaOverlayContainer = styled(Flex, {
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  flexDirection: 'column',
  opacity: 0,
  position: 'absolute',
  left: 0,
  top: 0,
  right: 0,
  padding: '$5',
  transition: 'opacity $2 $ease',
  background: '$overlay',
  width: '100%',
  height: '100%',
  color: '$white100',

  a: {
    // Must be above the ArtworkHiddenLink to remain interactive
    zIndex: 2,
  },

  // Targets the CollectionTag.
  [`${ArtworkCardCollectionWrapper} a`]: {
    position: 'relative',
    color: '$white100',
    transition: 'color $1 $ease',
    '@hover': {
      '&:hover': {
        color: '$white70',
      },
    },
  },
});
