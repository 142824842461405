import { UseQueryOptions } from '@tanstack/react-query';
import { keepPreviousData } from '@tanstack/react-query';

import {
  useAssetFallbackByArtworkIds as useAssetFallbackByArtworkIdsBaseHook,
  AssetFallbackByArtworkIdsVariables,
  AssetFallbackByArtworkIds,
} from 'gql/hasura/queries/asset-fallback-by-artwork-ids.generated';
import { CACHE_TIME } from 'lib/constants';

export default function useAssetFallbackByArtworkIds(
  variables: AssetFallbackByArtworkIdsVariables,
  options: Pick<
    UseQueryOptions<AssetFallbackByArtworkIds, Error>,
    'refetchOnWindowFocus' | 'enabled'
  > = {}
) {
  // artworkIds can be either string or string[]
  const artworkIds = variables?.artworkIds;

  return useAssetFallbackByArtworkIdsBaseHook(variables, {
    ...options,
    // first check if it is an array
    enabled: Array.isArray(artworkIds)
      ? // enable the query if there is at least one id in the array
        artworkIds.length > 0
      : // otherwise check if it’s a string and enable it then
        typeof artworkIds === 'string',
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    placeholderData: keepPreviousData,
    staleTime: CACHE_TIME,
  });
}
