import { getUnixTime } from 'date-fns';
import { AnimatePresence, motion } from 'framer-motion';
import { useState, useEffect, useMemo } from 'react';
import useIsInViewport from 'use-is-in-viewport';

import Pin from 'components/Pin';
import AspectRatio from 'components/base/AspectRatio';
import Box from 'components/base/Box';
import Image from 'components/base/Image';
import CardVideo from 'components/cards/shared/CardVideo';
import PreRevealIcon from 'components/collections/PreRevealIcon';
import MediaLoadingSpinner from 'components/media/MediaLoadingSpinner';

import useAssetReady from 'hooks/use-asset-ready';
import {
  getNoAssetArtworkUrl,
  isUnsupportedArtworkAsset,
} from 'utils/artwork/artwork';
import {
  buildNoAssetArtworkCardUrl,
  getFallbackAssetMediaType,
  findAssetUrl,
  getOptimizedAssetMediaType,
} from 'utils/assets';
import { isEmptyOrNil, isNumber, isValueInArray } from 'utils/helpers';

import { UserLight } from 'types/Account';
import { AlgoliaCollection } from 'types/Algolia';
import { AssetStatus } from 'types/Artwork';
import { AssetMimeType, MediaType } from 'types/Assets';
import { CollectionCardFragment } from 'types/Collection';
import { FallbackAssetFragment } from 'types/artwork/artwork';
import { LinkHref } from 'types/router';

import { ArtworkCardVariant } from '../types';

import ArtworkCardMediaContainer from './ArtworkCardMediaContainer';
import ArtworkCardMediaOverlay from './ArtworkCardMediaOverlay';
import ArtworkCardPendingMedia from './ArtworkCardPendingMedia';
import ArtworkCardUnsupportedMedia from './ArtworkCardUnsupportedMedia';
import ArtworkHiddenLink from './ArtworkHiddenLink';

const MotionImage = motion(Image);
const MotionCardVideo = motion(CardVideo);

interface ArtworkCardMediaProps {
  assetStatus: AssetStatus;
  optimizedAssetUrl: string;
  posterUrl?: string;
  name: string;
  collection: CollectionCardFragment | AlgoliaCollection;
  href: LinkHref;
  creator: UserLight;
  tokenId?: number;
  fallbackAsset: FallbackAssetFragment;
  isOversized?: boolean;
  forceRenderMedia?: boolean;
  variant: ArtworkCardVariant;
  itemCount?: number;
  isDragDropEnabled?: boolean;
  showPreRevealIcon?: boolean;
  mintedAt: number;
  mimeType: AssetMimeType;
}

export default function ArtworkCardMedia(props: ArtworkCardMediaProps) {
  const {
    assetStatus,
    optimizedAssetUrl,
    posterUrl,
    name,
    collection,
    href,
    creator,
    tokenId,
    fallbackAsset,
    isOversized,
    itemCount = 1,
    forceRenderMedia = false,
    variant,
    isDragDropEnabled = false,
    showPreRevealIcon = true,
    mintedAt,
    mimeType,
  } = props;
  const [hasBeenInViewport, setHasBeenInViewport] = useState(forceRenderMedia);
  const [isInViewport, targetRef] = useIsInViewport();
  const noAssetArtworkUrl = getNoAssetArtworkUrl(collection);

  const isJustMinted = useMemo(() => {
    const now = getUnixTime(new Date());
    const justMintedThreshold = now - 300; // 5 minutes ago
    return mintedAt > justMintedThreshold;
  }, []);

  useEffect(() => {
    if (isInViewport) {
      setHasBeenInViewport(true);
    }
  }, [isInViewport]);

  const isFallback = isUnsupportedArtworkAsset({
    assetStatus,
  });

  const isUnsupportedAsset = fallbackAsset ? false : isFallback;

  const url = findAssetUrl({
    isUnsupportedAsset,
    assetStatus,
    optimizedAssetUrl,
    publicUrl: fallbackAsset?.publicUrl,
  });

  const optimizedAssetMediaType = getOptimizedAssetMediaType(optimizedAssetUrl);

  const fallbackAssetMediaType = getFallbackAssetMediaType(
    fallbackAsset?.mimeType
  );

  // if isFallback and there is no fallbackAsset or no optimized asset
  const isFallbackEmptyState = isFallback
    ? isEmptyOrNil(fallbackAsset)
    : !optimizedAssetUrl;

  let media: JSX.Element | null = null;

  if (
    collection.contractType === 'HIGHLIGHT_GENERATIVE_SERIES' &&
    isFallback &&
    isJustMinted
  ) {
    /**
     * This handles an issue with Highlight Drops, specifically on Base, where the asset usually moves to a FAILED state
     * as soon as it has been minted, then ~30-60 seconds later it moves back to a success state.
     *
     * This patches that scenario, by showing a pending state for the first few minutes after minting if the asset status is FAILED.
     *
     * TODO: Fix in asset pipeline, or handle more gracefully
     */
    media = <ArtworkCardPendingMedia />;
  } else if (isFallbackEmptyState) {
    media = (
      <ArtworkCardUnsupportedMedia
        noAssetArtworkUrl={buildNoAssetArtworkCardUrl(noAssetArtworkUrl ?? '')}
      />
    );
  } else {
    media = (
      <RenderArtworkCardMedia
        isFallback={isFallback}
        url={url ?? ''}
        posterUrl={posterUrl ?? ''}
        name={name ?? ''}
        mediaType={
          isFallback ? fallbackAssetMediaType : optimizedAssetMediaType
        }
        hasBeenInViewport={hasBeenInViewport}
        collection={collection}
        isUnsupportedAsset={isUnsupportedAsset}
        forceRenderMedia={forceRenderMedia}
        isDragDropEnabled={isDragDropEnabled}
        showPreRevealIcon={showPreRevealIcon}
        mimeType={mimeType}
      />
    );
  }

  if (!media) return null;

  return (
    <ArtworkCardMediaContainer
      css={{ flexGrow: isOversized ? 1 : 0 }}
      ref={targetRef}
    >
      {isNumber(tokenId) && <ArtworkHiddenLink name={name} href={href} />}
      <AspectRatio
        ratio={isOversized ? null : 1 / 1}
        css={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: isOversized ? '100%' : 'auto',
        }}
      >
        <AnimatePresence exitBeforeEnter>{media}</AnimatePresence>
      </AspectRatio>
      {variant === 'nft' ? (
        <ArtworkCardMediaOverlay
          name={name}
          collection={collection}
          collectionCreator={creator}
          variant={variant}
          isFallback={isFallback}
        />
      ) : (
        <ArtworkCardMediaOverlay
          collection={collection}
          collectionCreator={creator}
          variant={variant}
          href={href}
          itemCount={itemCount}
          isFallback={isFallback}
        />
      )}
    </ArtworkCardMediaContainer>
  );
}

interface RenderArtworkCardMediaProps {
  collection: ArtworkCardMediaProps['collection'];
  hasBeenInViewport: boolean;
  mediaType: MediaType | undefined;
  url: string;
  posterUrl?: string;
  name: string;
  isUnsupportedAsset: boolean;
  isFallback: boolean;
  forceRenderMedia: boolean;
  isDragDropEnabled?: boolean;
  showPreRevealIcon?: boolean;
  mimeType: AssetMimeType;
}

function RenderArtworkCardMedia(props: RenderArtworkCardMediaProps) {
  const {
    collection,
    url,
    posterUrl,
    hasBeenInViewport,
    isUnsupportedAsset,
    mediaType,
    isFallback,
    forceRenderMedia,
    isDragDropEnabled,
    showPreRevealIcon,
  } = props;

  const assetStatus = useAssetReady(url, {
    enabled: mediaType === 'image',
  });
  const [imageLoaded, setImageLoaded] = useState(false);

  const isInPreRevealState =
    collection &&
    collection.contractType === 'FND_BATCH_MINT_REVEAL' &&
    !collection.isRevealed;

  const animationProps = {
    initial: { opacity: forceRenderMedia ? 1 : 0 },
    animate: { opacity: 1 },
    transition: { duration: 0.1 },
  };

  if (isUnsupportedAsset) {
    const noAssetArtworkUrl = collection
      ? getNoAssetArtworkUrl(collection)
      : url;

    return (
      <ArtworkCardUnsupportedMedia
        noAssetArtworkUrl={buildNoAssetArtworkCardUrl(noAssetArtworkUrl ?? '')}
      />
    );
  }

  if (
    !hasBeenInViewport &&
    isValueInArray(mediaType as MediaType, ['image', 'model'])
  ) {
    return (
      <motion.div {...animationProps} style={{ backgroundColor: '#F2F2F2' }} />
    );
  }

  if (mediaType === 'video') {
    return (
      <MotionCardVideo
        {...animationProps}
        // ensure URL is used if posterURL is null
        posterUrl={posterUrl}
        url={url}
      />
    );
  }

  if (mediaType === 'model') {
    return (
      <Box css={{ position: 'relative', height: '100%', width: '100%' }}>
        <MotionImage
          {...animationProps}
          loading="lazy"
          src={posterUrl}
          alt=""
          css={{
            display: 'block',
            objectFit: 'cover',
            width: '100%',
            height: '100%',
          }}
        />
      </Box>
    );
  }

  if (!isFallback && (assetStatus.isLoading || assetStatus.isError)) {
    return (
      <MediaLoadingSpinner
        isLoading={assetStatus.isLoading}
        color="$black100"
      />
    );
  }

  return (
    <>
      <MediaLoadingSpinner isLoading={!imageLoaded} color="$black100" />
      <MotionImage
        {...animationProps}
        loading="lazy"
        src={url}
        alt=""
        onLoad={() => setImageLoaded(true)}
        css={{
          display: 'block',
          objectFit: 'cover',
          width: '100%',
          height: '100%',
        }}
      />
      {!isDragDropEnabled && isInPreRevealState && showPreRevealIcon && (
        <Pin.TopRight
          css={{
            padding: '$5',
            zIndex: 10,
          }}
        >
          <PreRevealIcon />
        </Pin.TopRight>
      )}
    </>
  );
}
