import { styled } from '@f8n-frontend/stitches';

const Absolute = styled('div', {
  position: 'absolute',
});

const TopRight = styled(Absolute, {
  top: 0,
  right: 0,
});

const TopLeft = styled(Absolute, {
  top: 0,
  left: 0,
});

const BottomRight = styled(Absolute, {
  bottom: 0,
  right: 0,
});

const BottomLeft = styled(Absolute, {
  bottom: 0,
  left: 0,
});

const Pin = {
  TopRight,
  TopLeft,
  BottomRight,
  BottomLeft,
};

export default Pin;
