import { styled } from '@f8n-frontend/stitches';

import IconV2 from 'components/base/IconV2';
import Tooltip from 'components/base/Tooltip';

import SparklesIcon from 'assets/icons/sparkles.svg';

export default function PreRevealIcon() {
  return (
    <Tooltip content="Pre-reveal image">
      <IconContainer>
        <IconV2 icon={SparklesIcon} size={1} />
      </IconContainer>
    </Tooltip>
  );
}

const IconContainer = styled('div', {
  gap: '$1',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'flex-end',

  fontSize: '$1',
  color: '$white100',
  borderRadius: '$1',
  whiteSpace: 'nowrap',
  perspective: '150rem',
  fontWeight: '$semibold',

  padding: '$1',
  backgroundColor: '$black20',
  backdropFilter: 'blur(10px)',
  transition: 'color $2 $ease, background-color $2 $ease',

  '@hover': {
    '&:hover': {
      backgroundColor: '$black70',
    },
  },
});
