import Spinner from 'components/Spinner';
import Box from 'components/base/Box';
import Text from 'components/base/Text';

export default function ArtworkCardPendingMedia() {
  return (
    <Box
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        background: '$black5',
        gap: '$3',
      }}
    >
      <Spinner size={2} />
      <Text color="dim" size={1}>
        Syncing artwork
      </Text>
    </Box>
  );
}
