import { styled } from '@f8n-frontend/stitches';
import { forwardRef } from 'react';

import { UnsafeAny } from 'types/utils';

const StyledVideo = styled('video', {
  display: 'block',
  objectFit: 'cover',
  width: '100%',
  height: '100%',
  position: 'absolute',
});

interface CardVideoProps {
  url: string;
  posterUrl?: string;
  css?: UnsafeAny;
  onError?: () => void;
}

const CardVideo = forwardRef<HTMLVideoElement, CardVideoProps>((props, ref) => {
  const { url, posterUrl, css, onError } = props;

  return (
    <StyledVideo
      onError={onError}
      ref={ref}
      css={css}
      loop
      src={url}
      poster={posterUrl}
      autoPlay
      muted
      playsInline
    />
  );
});

CardVideo.displayName = 'CardVideo';

export default CardVideo;
