import { styled } from '@f8n-frontend/stitches';
import NextLink from 'next/link';

import Link from 'components/base/Link';

import { LinkHref } from 'types/router';

const VisuallyHiddenLink = styled(Link, {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: 1,
  textIndent: '-9999rem',
  color: '$black100',
});

interface ArtworkHiddenLinkProps {
  name: string;
  href: LinkHref;
}

export default function ArtworkHiddenLink(props: ArtworkHiddenLinkProps) {
  const { name, href } = props;
  return (
    <NextLink passHref href={href} prefetch={false}>
      <VisuallyHiddenLink>{name}</VisuallyHiddenLink>
    </NextLink>
  );
}
