import { styled } from '@f8n-frontend/stitches';

import Box from 'components/base/Box';

const ArtworkCardMediaContainer = styled(Box, {
  position: 'relative',

  '&:after': {
    position: 'absolute',
    height: '1px',
    bottom: 0,
    left: 0,
    right: 0,
    content: '""',
    display: 'block',
    backgroundColor: '$black5',
  },
});

export default ArtworkCardMediaContainer;
