import { AnimatePresence, motion } from 'framer-motion';

import SpinnerStroked from 'components/SpinnerStroked';
import Box from 'components/base/Box';

interface MediaLoadingSpinnerProps {
  isLoading: boolean;
  color?: string;
}

export default function MediaLoadingSpinner(props: MediaLoadingSpinnerProps) {
  const { isLoading, color = '$white100' } = props;
  return (
    <AnimatePresence initial>
      {isLoading && (
        <motion.div
          initial={{ opacity: 0 }}
          exit={{
            opacity: 0,
            // immediately hide the spinner, to avoid it overlapping the loaded media
            transition: { duration: 0, delay: 0 },
          }}
          animate={{
            opacity: 1,
            // only show the spinner when the media is taking a while to load
            transition: {
              duration: 0.2,
              delay: 0.8,
            },
          }}
        >
          <Box
            css={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
              color,
            }}
          >
            <SpinnerStroked size={32} />
          </Box>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
