import { styled } from '@f8n-frontend/stitches';

import { ArtworkUnsupportedMessageWrapper } from 'components/artworks/media/ArtworkUnsupportedMessageWrapper';
import Flex from 'components/base/Flex';

import AssetUnsupportedIcon from 'assets/icons/asset-unsupported-icon.svg';
import { getNoAssetArtworkLabel } from 'utils/artwork/artwork';
import { createCanvasBackground } from 'utils/styles';

type ArtworkCardUnsupportedMediaProps = {
  noAssetArtworkUrl?: string;
};

export default function ArtworkCardUnsupportedMedia(
  props: ArtworkCardUnsupportedMediaProps
) {
  return (
    <Flex
      center
      css={{
        width: '100%',
        height: '100%',
      }}
      style={createCanvasBackground({
        imageUrl: props.noAssetArtworkUrl,
      })}
    >
      <ArtworkCardUnsupportedMediaIconWrapper
        aria-label={getNoAssetArtworkLabel()}
      >
        <AssetUnsupportedIcon />
      </ArtworkCardUnsupportedMediaIconWrapper>
    </Flex>
  );
}

export const ArtworkCardUnsupportedMediaIconWrapper = styled(
  ArtworkUnsupportedMessageWrapper,
  {
    width: '56px',
    height: '56px',
    transition: 'opacity $1',
  }
);
