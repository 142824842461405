import Flex from 'components/base/Flex';
import { H3Heading } from 'components/base/Heading';
import IconV2 from 'components/base/IconV2';

import InfoIcon from 'assets/icons/info-icon.svg';

export default function FallbackTag() {
  return (
    <Flex
      css={{
        alignItems: 'center',
      }}
    >
      <IconV2 icon={InfoIcon} size={0} />
      <H3Heading size={1} css={{ marginLeft: '$2' }}>
        This is a fallback asset
      </H3Heading>
    </Flex>
  );
}
